import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import { getDistinctTaxrefBirdnet } from "./distinctTaxrefProvider";
import CopyButton from "../../components/CopyButton";

import { GBIFexplorerComponent, GBIFData } from "./GBIFexplorerComponent";
import Popup from "../../components/Popup";
import GBIFcellInfo from "./GBIFcellInfo";
import { getGbifSpeciesKey, upsertGBIFLocalID } from "./GBIFLocalIdProvider";

function Filters({
  onTaxrefChange,
}: {
  onTaxrefChange: (taxref: string) => void;
}) {
  const [taxref, setTaxref] = useState("");

  const handleTaxrefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaxref(e.target.value);
    onTaxrefChange(e.target.value);
  };

  return (
    <div
      style={{
        paddingBottom: "10px",
        textAlign: "left",
        width: "80%",
        margin: "0 auto",
      }}
    >
      <input
        type="text"
        placeholder="Taxref Birdnet"
        onChange={handleTaxrefChange}
      />
    </div>
  );
}

function GBIFcell({ taxref }: { taxref: string }) {
  const [gbifSpeciesKey, setGbifSpeciesKey] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getGbifSpeciesKey("birdnet", taxref).then((gbifSpeciesKey) => {
      setGbifSpeciesKey(gbifSpeciesKey);
      setLoading(false);
    });
  }, [taxref]);

  if (loading) {
    return <Spinner />;
  }

  return <GBIFcellInfo speciesKey={gbifSpeciesKey} />;
}

function Content({
  distinctTaxref,
  onTaxrefChange,
  birdnetTaxonomy,
  birdnetTaxonomyVernacular,
}: {
  distinctTaxref: any[];
  onTaxrefChange: (taxref: string) => void;
  birdnetTaxonomy: object;
  birdnetTaxonomyVernacular: object;
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [taxrefToEdit, setTaxrefToEdit] = useState("");

  const handleTaxrefChange = (taxref: string) => {
    onTaxrefChange(taxref);
  };

  async function selectSpecieCallback(gbifData: GBIFData) {
    console.log(gbifData);
    console.log(taxrefToEdit);
    await upsertGBIFLocalID("birdnet", taxrefToEdit, gbifData.speciesKey);
    setShowPopup(false);
    //reload the page
    window.location.reload();
  }

  function editTaxref(taxref: string) {
    console.log("taxref to edit", taxref);
    setTaxrefToEdit(taxref);
    setShowPopup(true);
  }
  function getBirdnetScientificName(taxref: string) {
    const label = birdnetTaxonomy[taxref];
    if (!label) {
      return "";
    }
    const scientificName = label.split("_")[0];
    return scientificName;
  }
  function getBirdnetVernacularName(taxref: string) {
    const scientificName = getBirdnetScientificName(taxref);
    const vernacularName = birdnetTaxonomyVernacular[scientificName];
    if (!vernacularName) {
      return "";
    }
    return vernacularName;
  }

  return (
    <div style={{ width: "100%", textAlign: "center", paddingBottom: "50px" }}>
        {distinctTaxref.length} elements
      <Filters onTaxrefChange={handleTaxrefChange} />
      <table className="admin-table" style={{ width: "80%", margin: "0 auto" }}>
        <thead>
          <tr>
            <th>Taxref Birdnet</th>
            <th>Birdnet scientific name</th>
            <th>Birdnet vernacular name</th>
            <th>GBIF scientific name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {distinctTaxref.map((t) => (
            <tr key={t.taxref_birdnet}>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{t.taxref_birdnet}</span>
                  <CopyButton val={t.taxref_birdnet} />
                </div>
              </td>
              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{getBirdnetScientificName(t.taxref_birdnet)}</span>
                  <CopyButton
                    val={getBirdnetScientificName(t.taxref_birdnet)}
                  />
                </div>
              </td>

              <td>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>{getBirdnetVernacularName(t.taxref_birdnet)}</span>
                  <CopyButton
                    val={getBirdnetVernacularName(t.taxref_birdnet)}
                  />
                </div>
              </td>
              <td>
                <GBIFcell taxref={t.taxref_birdnet} />
              </td>
              <td>
                <i
                  className="fa fa-edit"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    editTaxref(t.taxref_birdnet);
                  }}
                ></i>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showPopup && (
        <Popup
          title="Choisir une espèce"
          style={{
            width: "80%",
            margin: "0 auto",
            maxWidth: "5000px",
            height: "800px",
            overflow: "auto",
          }}
          onClose={() => setShowPopup(false)}
        >
          <GBIFexplorerComponent
            allowSelectSpecie={true}
            selectSpecieCallback={selectSpecieCallback}
            initialSearchTerm={getBirdnetScientificName(taxrefToEdit)}
          />
        </Popup>
      )}
    </div>
  );
}

function BirdnetGBIF() {
  const [loading, setLoading] = useState(true);
  const [distinctTaxref, setDistinctTaxref] = useState([]);
  const [filteredTaxref, setFilteredTaxref] = useState([]);
  const [birdnetTaxonomy, setBirdnetTaxonomy] = useState([]);
  const [birdnetTaxonomyVernacular, setBirdnetTaxonomyVernacular] = useState(
    {}
  );

  async function loadBirdnetTaxonomy() {
    const url =
      "https://raw.githubusercontent.com/kahst/BirdNET-Analyzer/refs/heads/main/birdnet_analyzer/eBird_taxonomy_codes_2021E.json";
    const birdnetTaxonomy = await fetch(url);
    const data = await birdnetTaxonomy.json();
    setBirdnetTaxonomy(data);
  }
  async function loadBirdnetTaxonomyVernacular() {
    const url =
      "https://raw.githubusercontent.com/kahst/BirdNET-Analyzer/refs/heads/main/birdnet_analyzer/labels/V2.4/BirdNET_GLOBAL_6K_V2.4_Labels_fr.txt";
    const birdnetTaxonomy = await fetch(url);
    const data = await birdnetTaxonomy.text();
    const lines = data.split("\n");
    const taxonomy = {};
    lines.forEach((line) => {
      const [key, value] = line.split("_");
      taxonomy[key] = value;
    });
    setBirdnetTaxonomyVernacular(taxonomy);
  }

  useEffect(() => {
    getDistinctTaxrefBirdnet().then((distinctTaxref) => {
      setDistinctTaxref(distinctTaxref);
      setFilteredTaxref(distinctTaxref);
      loadBirdnetTaxonomy().then(() => {
        loadBirdnetTaxonomyVernacular().then(() => {
          setLoading(false);
        });
      });
    });
  }, []);

  const handleTaxrefChange = (taxref: string) => {
    const filtered = distinctTaxref.filter((t) =>
      t.taxref_birdnet.toLowerCase().includes(taxref.toLowerCase())
    );
    setFilteredTaxref(filtered);
  };

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
          padding: "10px",
        }}
      >
        Relations Birdnet / GBIF
      </div>
      <div>
        {loading ? (
          <Spinner />
        ) : (
          <Content
            distinctTaxref={filteredTaxref}
            onTaxrefChange={handleTaxrefChange}
            birdnetTaxonomy={birdnetTaxonomy}
            birdnetTaxonomyVernacular={birdnetTaxonomyVernacular}
          />
        )}
      </div>
    </div>
  );
}

export default BirdnetGBIF;
