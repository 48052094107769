import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  GBIFexplorerComponent,
  GBIFData,
} from "../admin/taxref/GBIFexplorerComponent";
import { getGbifSpeciesKey } from "../admin/taxref/GBIFLocalIdProvider";
const Testts = () => {
  const [showPopup, setShowPopup] = useState(false);

  function selectSpecieCallback(gbifData: GBIFData) {
    console.log(gbifData);
  }

  return (
    <div className="mainpage">
      <button onClick={() => getGbifSpeciesKey("taxrefe1c", "accenteur mouchet")}>
        Get GBIF Species Key
      </button>
    </div>
  );
};

export default Testts;
