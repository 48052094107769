import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import {
  getNextCampaigns as getNextCampaignsApi,
  setNextCampaigns as setNextCampaignsApi,
  NextCampaign,
} from "../../dataProvider/nextCampaignProvider";
import Popup from "../../components/Popup";
import EditNextCampaignPopup from "./EditNextCampaignPopup";
import { getClientList } from "../ClientUtils";


  function getDaysLeft(targetDate: string) {
    const today = new Date();
    const [day, month, year] = targetDate.split("/");
    const collectionDate = new Date(
      parseInt(year),
      parseInt(month) - 1,
      parseInt(day)
    );
    const diffTime = collectionDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

function NextCampaignTableHeader() {
  return (
    <thead>
      <tr>
        <th>Client</th>
        <th>Date de collecte</th>
        <th>Qui</th>
        <th>Dans</th>
        <th>Capteurs collectés</th>
        <th></th>
      </tr>
    </thead>
  );
}

function NextCampaignTableRow({
  nextCampaign,
  editNextCampaignCallback,
  deleteNextCampaignCallback,
}: {
  nextCampaign: NextCampaign;
  editNextCampaignCallback: (nextCampaign: NextCampaign) => void;
  deleteNextCampaignCallback: (nextCampaign: NextCampaign) => void;
}) {

  return (
    <tr key={nextCampaign.id}>
      <td>{nextCampaign.client}</td>
      <td>{nextCampaign.date}</td>
      <td>{nextCampaign.who}</td>
      <td>{getDaysLeft(nextCampaign.date)} j</td>
      <td>{nextCampaign.sensor_list.join(", ")}</td>
      <td style={{ width: "100px" }}>
        <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
          <i
            className="fa fa-edit"
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            onClick={() => editNextCampaignCallback(nextCampaign)}
          ></i>
          <i
            className="fa fa-trash"
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (confirm("Voulez-vous vraiment supprimer cette campagne ?")) {
                deleteNextCampaignCallback(nextCampaign);
              }
            }}
          ></i>
        </div>
      </td>
    </tr>
  );
}

function NextCampaignTableBody({
  nextCampaigns,
  editNextCampaignCallback,
  deleteNextCampaignCallback,
}: {
  nextCampaigns: NextCampaign[];
  editNextCampaignCallback: (nextCampaign: NextCampaign) => void;
  deleteNextCampaignCallback: (nextCampaign: NextCampaign) => void;
}) {
  console.log("nextCampaigns", nextCampaigns);
  return (
    <tbody>
      {nextCampaigns.map((nextCampaign) =>
        NextCampaignTableRow({
          nextCampaign,
          editNextCampaignCallback,
          deleteNextCampaignCallback,
        })
      )}
    </tbody>
  );
}

function NextCampaignTable({
  nextCampaigns,
  editNextCampaignCallback,
  deleteNextCampaignCallback,
}: {
  nextCampaigns: NextCampaign[];
  editNextCampaignCallback: (nextCampaign: NextCampaign) => void;
  deleteNextCampaignCallback: (nextCampaign: NextCampaign) => void;
}) {
  return (
    <table className="admin-table" style={{ width: "80%", margin: "0 auto" }}>
      <NextCampaignTableHeader />
      <NextCampaignTableBody
        nextCampaigns={nextCampaigns}
        editNextCampaignCallback={editNextCampaignCallback}
        deleteNextCampaignCallback={deleteNextCampaignCallback}
      />
    </table>
  );
}

function ProchainesCampagnes() {
  const [loading, setLoading] = useState(true);
  const [nextCampaigns, setNextCampaigns] = useState<NextCampaign[]>([]);
  const [displayPastCampaigns, setDisplayPastCampaigns] = useState(false);
  const [showAddNextCampaignPopup, setShowAddNextCampaignPopup] =
    useState(false);
  const [nextCampaignToEdit, setNextCampaignToEdit] =
    useState<NextCampaign | null>(null);

  async function fetchNextCampaignsFromApi() {
    setLoading(true);
    const nextCampaigns = await getNextCampaignsApi();
    setNextCampaigns(nextCampaigns);
    setLoading(false);
  }

  useEffect(() => {
    fetchNextCampaignsFromApi();
  }, []);

  function editNextCampaignCallback(nextCampaign: NextCampaign) {
    setNextCampaignToEdit(nextCampaign);
    setShowAddNextCampaignPopup(true);
  }

  function deleteNextCampaignCallback(nextCampaign: NextCampaign) {
    const nextCampaignsCopy = [...nextCampaigns];
    nextCampaignsCopy.splice(
      nextCampaignsCopy.findIndex((nc) => nc.id === nextCampaign.id),
      1
    );
    setNextCampaigns(nextCampaignsCopy);
    saveToBdd(nextCampaignsCopy);
  }

  function getRandomId() {
    return Math.random().toString(36).substring(2, 15);
  }

  function getNewNextCampaign() {
    return {
      id: "",
      client: getClientList()[0],
      who: "Margaux",
      date: "",
      sensor_list: [],
    };
  }

  function newNextCampaignClick() {
    setNextCampaignToEdit(getNewNextCampaign());
    setShowAddNextCampaignPopup(true);
  }

  async function saveToBdd(nextCampaignsToSave: NextCampaign[]) {
    setLoading(true);
    await setNextCampaignsApi(nextCampaignsToSave);
    await fetchNextCampaignsFromApi();
    setLoading(false);
  }

  function saveNextCampaign(nextCampaign: NextCampaign) {
    const nextCampaignsCopy = [...nextCampaigns];
    const isNew = nextCampaign.id === "";
    if (isNew) {
      nextCampaign.id = getRandomId();
      nextCampaignsCopy.push(nextCampaign);
    } else {
      nextCampaignsCopy[
        nextCampaignsCopy.findIndex((nc) => nc.id === nextCampaign.id)
      ] = nextCampaign;
    }
    setNextCampaigns(nextCampaignsCopy);
    setShowAddNextCampaignPopup(false);
    saveToBdd(nextCampaignsCopy);
  }

  function getCampaignsToDisplay() {
    if (displayPastCampaigns) {
      return nextCampaigns;
    }
    return nextCampaigns.filter((nc) => getDaysLeft(nc.date) > 0);
  }

  function Content() {
    return (
      <>
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{
              marginBottom: "20px",
              width: "80%",
              margin: "0 auto",
              textAlign: "left",
              paddingBottom: "20px",
            }}
          >
            <label
              style={{
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                checked={displayPastCampaigns}
                onChange={() => setDisplayPastCampaigns(!displayPastCampaigns)}
              />
              Afficher les campagnes passées
            </label>
          </div>
          <NextCampaignTable
            nextCampaigns={getCampaignsToDisplay()}
            editNextCampaignCallback={editNextCampaignCallback}
            deleteNextCampaignCallback={deleteNextCampaignCallback}
          />
          <button
            style={{
              padding: "5px 10px",
              fontSize: "16px",
              backgroundColor: "#4CAF50",
              color: "white",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "30px",
            }}
            onClick={() => newNextCampaignClick()}
          >
            Ajouter une campagne
          </button>
          {showAddNextCampaignPopup && (
            <Popup
              title="Editer une campagne"
              onClose={() => setShowAddNextCampaignPopup(false)}
              style={{ maxWidth: "800px" }}
            >
              <EditNextCampaignPopup
                nextCampaign={nextCampaignToEdit}
                onSave={saveNextCampaign}
                onCancel={() => setShowAddNextCampaignPopup(false)}
              />
            </Popup>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="mainpage">
      <div
        style={{
          marginBottom: "20px",
          textAlign: "center",
          fontSize: "25px",
          fontWeight: "bold",
          padding: "10px",
        }}
      >
        Prochaines campagnes
      </div>
      <div>{loading ? <Spinner /> : <Content />}</div>
    </div>
  );
}

export default ProchainesCampagnes;
