import React from "react";

const Filters = function ({ filtersState, filtersChangeCallback }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          gap: "30px",
          textAlign: "center",
          width: "80%",
          margin: "20px auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Clients:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("clients", "invert")}
            ></i>
          </span>

          {filtersState.clients.map((client) => (
            <div style={{ cursor: "pointer" }} key={client.key}>
              <input
                id={`client-${client.key}`}
                type="checkbox"
                checked={client.value}
                onChange={(e) =>
                  filtersChangeCallback("clients", client.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`client-${client.key}`}
              >
                {client.key}
              </label>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Status:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("status", "invert")}
            ></i>
          </span>
          {filtersState.status.map((status) => (
            <div style={{ cursor: "pointer" }} key={status.key}>
              <input
                id={`status-${status.key}`}
                type="checkbox"
                checked={status.value}
                onChange={(e) =>
                  filtersChangeCallback("status", status.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`status-${status.key}`}
              >
                {status.key}
              </label>
            </div>
          ))}
        </div>


        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Status details:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("statusDetails", "invert")}
            ></i>
          </span>
          {filtersState.statusDetails.map((status) => (
            <div style={{ cursor: "pointer" }} key={status.key}>
              <input
                id={`statusDetails-${status.key}`}
                type="checkbox"
                checked={status.value}
                onChange={(e) =>
                  filtersChangeCallback("statusDetails", status.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`statusDetails-${status.key}`}
              >
                {status.key}
              </label>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Modèle capteur:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("modele", "invert")}
            ></i>
          </span>
          {filtersState.modele.map((modele) => (
            <div style={{ cursor: "pointer" }} key={modele.key}>
              <input
                id={`modele-${modele.key}`}
                type="checkbox"
                checked={modele.value}
                onChange={(e) =>
                  filtersChangeCallback("modele", modele.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`modele-${modele.key}`}
              >
                {modele.key}
              </label>
            </div>
          ))}
        </div>


        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <span style={{ marginBottom: "3px", fontWeight: "bold" }}>
            Propriétaire:
            <i
              className="fa-solid fa-arrow-right-arrow-left"
              style={{ cursor: "pointer", marginLeft: "10px" }}
              onClick={() => filtersChangeCallback("proprietaire", "invert")}
            ></i>
          </span>
          {filtersState.proprietaire.map((proprietaire) => (
            <div style={{ cursor: "pointer" }} key={proprietaire.key}>
              <input
                id={`proprietaire-${proprietaire.key}`}
                type="checkbox"
                checked={proprietaire.value}
                onChange={(e) =>
                  filtersChangeCallback("proprietaire", proprietaire.key, e.target.checked)
                }
              />
              <label
                style={{ cursor: "pointer" }}
                htmlFor={`proprietaire-${proprietaire.key}`}
              >
                {proprietaire.key}
              </label>
            </div>
          ))}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            border: "1px solid #ccc",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <label
            htmlFor="donneesFinancieres"
            style={{
              marginBottom: "3px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            Données financières:
          </label>
          <input
            id="donneesFinancieres"
            type="checkbox"
            checked={filtersState.donneesFinancieres}
            onChange={(e) =>
              filtersChangeCallback("donneesFinancieres", e.target.checked)
            }
          />
        </div>
      </div>
    </>
  );
};

export default Filters;
