import { get, post } from "../../ConfigProvider";

function getGBIFLocalIDRessourceName(): string {
  return "gbifLocalID";
}

function getAllowedKeyTypes(): string[] {
  return ["taxrefe1c", "birdnet"];
}

async function fetchWithBackoff(url: string, attempt: number): Promise<any> {
  const maxAttempts = 20;
  try {
    return await get(url);
  } catch (error) {
    if (attempt < maxAttempts) {
      await new Promise((resolve) => setTimeout(resolve, 200));
      return fetchWithBackoff(url, attempt + 1);
    }
    throw error;
  }
}
async function getGbifSpeciesKey(
  keyType: string,
  key: string
): Promise<number | null> {
  const data = await getGBIFLocalID(keyType, key);
  if (!data) {
    return null;
  }

  const speciesKey = data.gbif_species_key;

  return speciesKey;
}

async function getGBIFLocalID(
  keyType: string,
  key: string
): Promise<number | null> {
  if (!keyType || !key) {
    throw new Error("keyType or key is required, got keyType: " + keyType + " and key: " + key);
  }

  if (!getAllowedKeyTypes().includes(keyType)) {
    throw new Error(
      "Invalid key type,valid key types are: " + getAllowedKeyTypes().join(", ")
    );
  }

  let url = getGBIFLocalIDRessourceName();
  url += "?keyType=" + keyType + "&key=" + key;
  const data = await fetchWithBackoff(url, 0);


  if (!data) {
    return null;
  }
  if (!Array.isArray(data)) {
    return null;
  }
  if (data.length === 0) {
    return null;
  }
  return data[0];
}

async function upsertGBIFLocalID(
  keyType: string,
  key: string,
  gbifSpeciesKey: number
) {
  let url = getGBIFLocalIDRessourceName();
  url +=
    "?keyType=" + keyType + "&key=" + key + "&gbifSpeciesKey=" + gbifSpeciesKey;
  await post(url);
}

export { getGBIFLocalID, upsertGBIFLocalID, getGbifSpeciesKey };
