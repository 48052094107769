import { get } from "../../ConfigProvider";

function getDistinctTaxrefRessourceName(): string {
  return "taxrefDistincts";
}

function orderByTaxrefE1c(distinctTaxref: any[]): any[] {
  return distinctTaxref.sort((a, b) => a.taxref.localeCompare(b.taxref));
}

function orderByTaxrefBirdnet(distinctTaxref: any[]): any[] {
  return distinctTaxref.sort((a, b) => {
    if (a.taxref_birdnet == null) {
      return 1;
    }
    if (b.taxref_birdnet == null) {
      return -1;
    }
    return a.taxref_birdnet.localeCompare(b.taxref_birdnet);
  });
}

async function getDistinctTaxref(keyType: string): Promise<[]> {
  let url = getDistinctTaxrefRessourceName();
  url += "?keyType=" + keyType;
  const data = await get(url);
  console.log(data);

  const distinctTaxref = data.distinctTaxref;
  if (!distinctTaxref) {
    return [];
  }

  return distinctTaxref;
}

async function getDistinctTaxrefE1c(): Promise<[]> {
  const distinctTaxref = await getDistinctTaxref("taxrefe1c");
  return orderByTaxrefE1c(distinctTaxref);
}

async function getDistinctTaxrefBirdnet(): Promise<[]> {
  const distinctTaxref = await getDistinctTaxref("birdnet");

  //remove the null taxref_birdnet  
  const distinctTaxrefFiltered = distinctTaxref.filter(
    (t) => t.taxref_birdnet != null
  );
  return orderByTaxrefBirdnet(distinctTaxrefFiltered);
}

export { getDistinctTaxrefE1c, getDistinctTaxrefBirdnet };
