import React, { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import CopyButton from "../../components/CopyButton";

export default function GBIFcellInfo({
  speciesKey,
}: {
  speciesKey: number;
}) {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");

  useEffect(() => {
    const fetchName = async () => {
      if (!speciesKey) {
        setLoading(false);
        return;
      }

      const url = `https://api.gbif.org/v1/species/${speciesKey}/name`;
      const response = await fetch(url);
      const data = await response.json();
      setName(data.scientificName);
      setLoading(false);
    };
    fetchName();
  }, [speciesKey]);

  if (loading) {
    return <Spinner />;
  }
  if (!name) {
    return <div>--</div>;
  }

  return (
    <div>
      <a href={`https://www.gbif.org/species/${speciesKey}`} target="_blank">
        {name}
      </a>
      &nbsp;
      <CopyButton val={name} />
      &nbsp;
      &nbsp;
      -

      &nbsp;
      &nbsp;
      {speciesKey}
      &nbsp;
      <CopyButton val={speciesKey.toString()} />
    </div>
  );
}
