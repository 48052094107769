import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import e1cconfig from "./e1cconfiguration.json";

var cacheSession = null;

const signout = () => {
  cacheSession = null;
};

const getAuthToken = async () => {
  const session = await getAuthSession();
  return session.tokens?.idToken?.toString();
};

const getAuthSession = async () => {
  if (cacheSession) {
    return cacheSession;
  }

  const session = await fetchAuthSession();
  cacheSession = session;
  return session;
};

function getConfigRessourceName(): string {
  return "config";
}

function getKvConfigRessourceName(): string {
  return "kvConfig";
}
function getGetSuperSetDashboardTokenRessourceName(): string {
  return "getSuperSetDashboardToken";
}

function getConfigUrl(): URL {
  return new URL(`${e1cconfig.apiurl}${getConfigRessourceName()}`);
}

function getKvConfigUrl(): URL {
  return new URL(`${e1cconfig.apiurl}${getKvConfigRessourceName()}`);
}

const get = async function (url: string) {
  const authToken = await getAuthToken();
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(`${getApiUrl()}${url}`, requestOptions);
  const data = await response.json();
  return data;
};

const post = async function (url: string, data: any) {
  const authToken = await getAuthToken();
  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${getApiUrl()}${url}`, requestOptions);
  const responseData = await response.json();
  return responseData;
};

const getConfig = async (configcategory: string) => {
  let url: string = getConfigRessourceName();
  url += "?configcategory=" + configcategory;
  const urlstr = url.toString();
  const conf = await get(urlstr);
  return conf;
};

const getConfigAsAdmin = async (configcategory: string, client: string) => {
  const username = await getUserName();
  if (!username.startsWith("e1c_")) {
    throw new Error("User is not admin");
  }

  let url: string = getConfigRessourceName();
  url +=
    "?configcategory=" + configcategory + "&client=" + client + "&admin=true";
  const urlstr = url.toString();
  const conf = await get(urlstr);
  return conf;
};

const getKvConfig = async (key: string) => {
  let url: string = getKvConfigRessourceName();
  url += "?key=" + key;
  const urlstr = url.toString();
  const conf = await get(urlstr);
  return conf;
};

const writeConfig = async function (configcategory: string, confobj) {
  try {
    const authToken = await getAuthToken();

    const url = getConfigUrl();
    url.searchParams.append("configcategory", configcategory);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(confobj),
    };

    const response = await fetch(url, requestOptions);
    console.log("response", response);

    if (response.ok) {
    } else {
      alert("Error writing config");
    }

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

const writeKvConfig = async function (key: string, confobj) {
  try {
    const authToken = await getAuthToken();
    const url = getKvConfigUrl();
    url.searchParams.append("key", key);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: authToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(confobj),
    };

    const response = await fetch(url, requestOptions);

    if (response.ok) {
    } else {
      alert("Error writing kvconfig");
    }

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

const getUserGroups = async () => {
  const session = await getAuthSession();
  return session.tokens?.idToken?.payload["cognito:groups"];
};

const getClientName = async () => {
  const usergroups = await getUserGroups();
  return usergroups[0];
};

const getUserName = async () => {
  const user = await getCurrentUser();
  return user.username;
};

const getStage = () => {
  return e1cconfig.stage;
};

const getPropertyBoundary = async () => {
  const config = await getConfig("coordinates");
  return config.propertyBoundary;
};

const getPropertyCoordinates = async () => {
  const config = await getConfig("coordinates");

  return {
    latitude: config.latitude,
    longitude: config.longitude,
    zoomLevel: config.zoomLevel,
  };
};

const setPropertyCoordinates = async (
  latitude: number,
  longitude: number,
  zoomLevel: number
) => {
  const oldConfig = await getConfig("coordinates");

  const newConfig = {
    ...oldConfig,
    latitude,
    longitude,
    zoomLevel,
  };

  await writeConfig("coordinates", newConfig);
};

const setPropertyBoundary = async (propertyBoundary: string) => {
  const oldConfig = await getConfig("coordinates");
  const newConfig = {
    ...oldConfig,
    propertyBoundary,
  };
  await writeConfig("coordinates", newConfig);
};

const getKvConfigValue = async (key: string) => {
  const config = await getKvConfig(key);
  return config.value;
};

const getCampaignsStatus = async () => {
  return await getKvConfigValue("campaignsStatus");
};

const getDepositState = async () => {
  return await getKvConfigValue("depositState");
};

const getInventaireCapteursKey = () => {
  return "inventaireCapteurs";
};

const getInventaireCapteurs = async () => {
  const value = await getKvConfigValue(getInventaireCapteursKey());
  return value;
};

const setInventaireCapteurs = async (value) => {
  await writeKvConfig(getInventaireCapteursKey(), value);
};

const getGeologyGeoJsonUrl = async () => {
  const config = await getConfig("coordinates");
  return config.geologyGeoJsonUrl;
};

async function getNatureSolsGeoJsonUrl() {
  const clientName = await getClientName();
  if (clientName === "lapeyruche") {
    return "/natureSols/langoiran.geojson";
  }
  if (clientName === "porquerolles") {
    return "/natureSols/porquerolles.geojson";
  }
  if (clientName === "etrechy") {
    return "/natureSols/etrechy.geojson";
  }
  if (clientName === "maris") {
    return "/natureSols/herault.geojson";
  }
  if (clientName === "louisedelavalliere") {
    return "/natureSols/indreetloire.geojson";
  }
  return null;
}

const getGetSuperSetDashboardToken = async (dashboardId: string) => {
  let url: string = getGetSuperSetDashboardTokenRessourceName();
  url += "?dashboardId=" + dashboardId;
  const data = await get(url);
  return data.guestToken;
};

const getApiUrl = () => {
  return e1cconfig.apiurl;
};

export {
  getPropertyBoundary,
  getClientName,
  getStage,
  signout,
  getPropertyCoordinates,
  getUserName,
  getGeologyGeoJsonUrl,
  getNatureSolsGeoJsonUrl,
  getApiUrl,
  getUserGroups,
  getConfig,
  writeConfig,
  get,
  post,
  getCampaignsStatus,
  getConfigAsAdmin,
  getInventaireCapteurs,
  setInventaireCapteurs,
  getDepositState,
  setPropertyCoordinates,
  setPropertyBoundary,
  getGetSuperSetDashboardToken,
  getKvConfig,
  writeKvConfig,
};
